<!--====================================================================================================================-->
<!-- Barra de navegción ================================================================================================-->
<!--====================================================================================================================-->
<nav class="d-flex align-items-center justify-content-between justify-content-sm-start p-1 px-3 shadow-sm bg-white w-100" style="height: 50px; position: fixed; z-index: 80;">
        <!-- Version Web -->
        <h4 class="m-0 p-0 w-50 d-none d-sm-block">{{nombreModulo}}</h4>
        <div style="width: 120px;" class="d-sm-none">
                <img class="img-fluid" src="../../../../assets/img/TruemedGroupLogo.jpeg">
        </div>
        <div class="w-25">
                <button type="button" class="btn d-block d-sm-none float-end" (click)="openSideBar()"><i class="fas fa-bars float-end" style="font-size: 1.5rem;"></i></button> 
        </div>
</nav>